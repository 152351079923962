@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #999 transparent;
}

*::-webkit-scrollbar {
  height: 2px !important;
  width: 2px !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border: transparent;
  border-radius: 25px;
}

body {
  background: #f4f4f7;
}

.media_submit_wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InviteContent.css-1h05724 {
  margin: 7rem auto;
  max-width: 920px;
  width: 100%;
  padding: 1rem;
}
.InviteContent.css-1h05724.full_width {
  margin: 7rem 1rem;
  max-width: unset;
}

.css-8tmyua {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  overflow-x: hidden;
  border-radius: 15px;
  width: 100%;
  max-width: 100%;
  /* max-height: 917px; */
  padding-top: 2rem;
  border: 1px solid #a4a4c04d;
}
.success_page_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(
    135.09deg,
    #00aab8 1.31%,
    #df196d 48.63%,
    #ee7923 98.54%
  );
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  overflow: hidden;
  border-radius: 15px;
  width: 100%;
  max-width: 100%;
  /* max-height: 917px; */
  padding: 175px 16px 16px;
  border: none;
  position: relative;
}
.success_page_box > img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.success_page_box .css-7x17c8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.success_page_box .css-7x17c8 img {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-weight: 800;
  z-index: 0;
}
.success_page_box h4 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-weight: 800;
  margin: 20px 0 80px;
  z-index: 0;
}
.success_page_box .upload_footer {
  background: #f4f4f7;
  border-radius: 0 0 15px 15px;
  padding: 26px 16px;
}
.upload_file_preview {
  background-color: #d9d9d9;
  border-radius: 12px;
  position: relative;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
}
.upload_file_preview > button {
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  z-index: 1;
  filter: brightness(10);
}
.upload_file_preview > p{
  position: absolute;
  color: #fff;
  font-weight: 500;
  top: 10px;
  left: 10px;
  margin: 0;
  width: 80%;
}
.upload_file_preview > .webcam_preview{
  aspect-ratio: 1;
}
.media_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.chakra-heading.css-wp9238 {
  font-weight: 600;
  color: #263340;
  outline: transparent solid 2px;
  outline-offset: 2px;
  font-size: 1.25rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 0;
}

.uppy-Dashboard-inner {
  /* min-height: 400px !important; */
  width: 100% !important;
  padding: 36px 90px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uppy-Dashboard-innerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135.09deg,
    #00aab8 1.31%,
    #df196d 48.63%,
    #ee7923 98.54%
  );
  border-radius: 18px;
  padding: 10px;
}
.uppy-Dashboard-innerWrap > div {
  height: 100%;
  width: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff30;
  border-radius: 12px;
  border: 2px dashed #ddd;
}
.uppy-Dashboard-innerWrap.light_bg {
  background: transparent;
}
.uppy-Dashboard-innerWrap.light_bg > div {
  border: none;
}
.info_text {
  font-size: 14px;
  font-weight: 500;
  color: #888;
  margin: 0;
}
[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
  /* border: 1px dashed #dfdfdf; */
  border-radius: 3px;
  height: calc(100% - 14px);
  /* min-height: 350px; */
  margin: 12px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}

.uppy-size--md .uppy-Dashboard-AddFiles-title {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  max-width: 480px;
  padding: 0 35px;
  text-align: center;
  line-height: 22px;
  letter-spacing: 0.4px;
}

.uppy-size--md .uppy-Dashboard-AddFiles-list {
  display: flex;
  gap: 25px;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.uppy-size--md .uppy-DashboardTab {
  border-bottom: none;
  display: inline-block;
  width: auto;
}

.uppy-DashboardTab-btn,
[dir="ltr"] .uppy-size--md .uppy-DashboardTab-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0;
  cursor: pointer;
  flex-direction: column;
  padding: 0;
  width: 68px;
  appearance: none;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.uppy-DashboardTab-inner {
  background: #f4f4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
}
.uppy-DashboardTab-iconMyDevice {
  height: 32px;
  transform: scale(2);
  color: #1269cf;
}

.uppy-DashboardTab svg {
  height: 32px;
  transform: scale(2);
}

.uppy-DashboardTab-iconAudio {
  color: #8030a3;
  height: 32px;
  transform: scale(2);
}

.upload_footer {
  padding: 30px 0;
  background-color: #f4f4f7;
}

.css-q76kqc {
  width: var(--vidday-sizes-full);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.css-be7bzb {
  color: #263340;
  font-weight: 700;
}

.css-14lxv93 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
}

.css-sqxod8 {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: 25px;
  font-weight: 600;
  transition-property: var(--vidday-transition-property-common);
  transition-duration: 200ms;
  height: 2.25rem;
  min-width: 2.5rem;
  font-size: 16px;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: #0066cc;
  color: #0066cc;
  background: transparent;
}

.css-sqxod8:hover {
  color: #fff;
  background: #0066cc;
}
.video_screen_capture,
.video_screen {
  padding: 10px;
  border-radius: 18px;
  background: linear-gradient(
    135.09deg,
    #00aab8 1.31%,
    #df196d 48.63%,
    #ee7923 98.54%
  );
}
.video_screen > .webcam_preview {
  border: 1px solid #ddd;
}
.webcam_preview {
  /* aspect-ratio: 16/9; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin: 0;
  border-radius: 13px;
}

.assets_upload_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 2.5rem;
}
.timer_display {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.timer_display > div {
  background: linear-gradient(135.09deg, #00aab8 1.31%, #df196d 48.63%, #ee7923 98.54%);
  padding: 2px;
  border-radius: 12px;
  overflow: hidden;
}
.timer_display > div > p {
  border-radius: 10px;
  background: #fff;
  margin: 0;
  font-size: 18px;
  color: #777;
  font-weight: 500;
  padding: 2px 12px;
}

/* .assets_upload_btns button {
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: 25px;
  font-weight: 600;
  transition-duration: 200ms;
  height: 2.25rem;
  min-width: 2.5rem;
  font-size: 16px;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: #0066cc;
  color: #0066cc;
  background: transparent;
}

.assets_upload_btns button:hover {
  color: #fff;
  background: #0066cc;
} */

.audio_not_recording {
  aspect-ratio: 20/5;
  height: 150px;
  background-color: #c8c8c8;
  position: relative;
}

.audio_not_recording::after {
  position: absolute;
  content: "";
  display: flex;
  width: 100%;
  top: 50%;
  height: 2px;
  background-color: #222;
}

.audio-react-recorder {
  background: #fff;
  overflow: hidden;
  border-radius: 12px;
  max-height: 250px;
  display: flex;
}
.audio-react-recorder canvas {
  width: 100%;
  mix-blend-mode: hard-light;
}
.audio_controls {
  background: #f1f3f4;
  width: 100%;
  border-radius: 10px;
}
.modal-header {
  border: none;
}

.add_email_modal {
  padding: 0px;
}

.add_email_modal input {
  width: 100%;
  border: 1px solid #a4a4c04d;
  border-radius: 8px;
  padding: 16px 18px;
  outline: none;
}
.add_email_modal input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  color: #a4a4c0;
}

.btn_primary {
  padding: 13px 45px;
  background: linear-gradient(
    135.09deg,
    #00aab8 1.31%,
    #df196d 48.63%,
    #ee7923 98.54%
  );
  border-radius: 50px;
  border: 0;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
}

.btn_secondary {
  padding: 13px 30px;
  border-radius: 50px;
  border: 0;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  background-color: #24242d;
}

.gradient_btn {
  padding: 8px 30px;
  background: linear-gradient(
    135.09deg,
    #00aab8 1.31%,
    #df196d 48.63%,
    #ee7923 98.54%
  );
  border-radius: 50px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
  height: 50px;
}
.dark_btn {
  padding: 8px 30px;
  height: 50px;
  background: #24242d;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
}
.trnsprnt_btn {
  padding: 8px 30px;
  height: 50px;
  background: transparent;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #24242D;
  text-decoration: none;
  border: 1px solid #A4A4C04D;
}
.gradient_btn_sm {
  padding: 6px 20px;
  background: linear-gradient(
    135.09deg,
    #00aab8 1.31%,
    #df196d 48.63%,
    #ee7923 98.54%
  );
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
}
.dark_btn_sm {
  padding: 6px 20px;
  background: #24242d;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 991px) {
  .video_screen_preview {
    padding: 30px 0 90px;
  }
}
@media (max-width: 915px) {
  .uppy-Dashboard-innerWrap > div {
    flex-direction: column;
  }
  .upload_file_preview {
    width: 100%;
  }
  .upload_file_preview > .webcam_preview {
    aspect-ratio: unset;
  }
}
@media (max-width: 767px) {
  .video_screen_preview .uppy-Dashboard-AddFiles > div > .row{
    flex-direction: column;
  }
  .upload_file_preview > .webcam_preview {
    aspect-ratio: 4/3;
  }
}
@media (max-width: 525px) {
  .InviteContent.css-1h05724 {
    margin: 0;
  }
  .chakra-heading.css-wp9238,
  .chakra-text.css-be7bzb {
    padding: 0 16px;
  }
  .uppy-Dashboard-inner {
    padding: 16px;
  }
  .uppy-size--md .uppy-Dashboard-AddFiles-title {
    padding: 0;
  }
  .assets_upload_btns {
    flex-direction: column;
  }
  .assets_upload_btns button {
    width: 100%;
  }
  .gradient_btn_sm,
  .dark_btn_sm {
    padding: 10px 20px;
  }
}
