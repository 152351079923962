body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.invitation {
  padding: 114px 0;
  position: relative;
  &::after {
    content: "";
    height: 450px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    background-image: url("./assets/welcome_back.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
  }
  .birth_title {
    .title {
      font-size: 36px;
      font-weight: 800;
      line-height: 54px;
      letter-spacing: 0px;
      color: #fff;
      margin-bottom: 9px;
    }
    .name {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
      color: #fff;
      margin-bottom: 18px;
    }
  }
  .tabs_wrapper {
    border: 1px solid #a4a4c04d;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
    .tab_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      img {
        width: 50px;
        margin-right: 10px;
      }
      .tab_text {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        p {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: 0px;
          color: #24242d;
          text-transform: capitalize;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0px;
          color: #a4a4c0;
          text-transform: capitalize;
        }
      }
    }
    // .MuiButtonBase-root-MuiTab-root.Mui-selected{
    .MuiBox-root {
      border-bottom: 0;
      .MuiTabPanel-root {
        padding-top: 64px;
      }
      .MuiTabs-flexContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #f4f4f7;
        .MuiButtonBase-root {
          padding: 27px 30px;
          width: 20%;
          &.Mui-selected {
            background-color: #fff;
          }
        }
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    .participate_image {
      height: 619px;
      width: 100%;
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
      .camera {
        height: 70px;
        width: 70px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 28px;
          height: auto;
        }
        input {
          display: none;
        }
      }
      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(20px);
        padding: 22px;
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        color: #fff;
        background-color: #00000047;
      }
    }
    .participate_content {
      border: 1px solid #a4a4c04d;
      border-radius: 15px;
      padding: 30px 30px 58px 30px;
      margin-bottom: 25px;
      .content_title {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        color: #24242d;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: #a4a4c0;
        margin: 25px 0 0 0;
      }
      textarea {
        width: 100%;
        border: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        resize: none;
        &:focus-visible {
          border: none;
          outline: none !important;
        }
      }
    }
    .add_video {
      margin-bottom: 25px;
      border: 1px solid #a4a4c04d;
      border-radius: 15px;
      // padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      input {
        display: none;
      }
      .video_image {
        margin-bottom: 10px;
      }
      .video_title {
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: #a4a4c0;
      }
    }
    .input_box {
      border: 1px solid #a4a4c04d;
      margin-top: 25px;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      &.deadline {
        margin-top: 20px;
      }
      &.add_manually {
        border: 0;
        .input_lable {
          padding: 0;
        }
      }
      .deadline_date {
        font-size: 20px;
      }
      .input_lable {
        padding: 22px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        &.left {
          justify-content: left;
        }
        img {
          width: 36px;
          height: 36px;
          margin-right: 20px;
        }
        .send {
          button {
            padding: 10px 35px;
            text-align: center;
            background: linear-gradient(
              135.09deg,
              #00aab8 1.31%,
              #df196d 48.63%,
              #ee7923 98.54%
            );
            border-radius: 50px;
            border: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .custom_date {
        padding: 26px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        user-select: none;
        background: hsl(240deg 15.79% 96.27%);
        &.select_csv {
          display: flex;
          justify-content: center;
          align-items: center;
          input {
            display: none;
          }
          span {
            color: #a4a4c0;
          }
        }
        span {
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0px;
          color: #24242d;
        }
      }
      .input_fields {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        input {
          width: 48%;
          border: 1px solid #a4a4c04d;
          border-radius: 8px;
          padding: 16px 18px;
          outline: none;
          margin-right: 24px;
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            color: #a4a4c0;
          }
        }
        .add,
        .remove {
          background-color: transparent;
          border: 0;
        }
      }
    }
  }
  .action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    .back {
      background-color: #24242d;
      padding: 13px 36px;
      border-radius: 50px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      border: 0px;
      color: #fafafb;
      margin-right: 15px;
    }
    .submit {
      padding: 13px 45px;
      min-width: 250px;
      text-align: center;
      background: linear-gradient(
        135.09deg,
        #00aab8 1.31%,
        #df196d 48.63%,
        #ee7923 98.54%
      );
      border-radius: 50px;
      border: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      color: #fff;
      text-decoration: none;
    }
  }
}

.message_content {
  margin-left: 16px;
  .share_link {
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      135.09deg,
      #00aab814 1.31%,
      #df196d14 48.63%,
      #ee792314 98.54%
    );
    padding: 14px 25px 14px 29px;
    .content {
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #24242d;
      }
      .link {
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;
        font-size: 20px;
        text-decoration: none;
        background: linear-gradient(
          135.09deg,
          #00aab8 1.31%,
          #df196d 48.63%,
          #ee7923 98.54%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .share_option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
    .share_box {
      &:not(:last-child) {
        margin-right: 19px;
      }
      .share_icon {
        width: 88.5px;
        height: 88.5px;
        border-radius: 9px;
        border: 0.91px solid #a4a4c04d;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 27px;
        }
      }
      .share_title {
        margin-top: 9px;
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: center;
        color: #24242d;
      }
    }
  }
}
.message_image {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("./assets/video_created.svg");
  background-size: cover;
  background-position: center;
  margin-right: 12px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .mail_box {
    position: relative;
    .birth_name {
      position: absolute;
      padding: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 27px;
        font-weight: 800;
        line-height: 40px;
        width: 140px;
      }
    }
  }
  .birth_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .preview_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    button {
      padding: 10px 20px;
      border-radius: 50px;
      border: 0px;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      color: #24242d;
    }
  }
}

.media_type_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .media_row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #a4a4c04d;
    border-radius: 15px;
    padding: 22px 25px;
    user-select: none;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      margin-left: 20px;
    }
  }
}

.selected_wrapper {
  border: 1px solid #a4a4c04d;
  border-radius: 15px;
  padding: 20px 25px;
  height: 100%;
  .selected_graphics {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .cards {
      width: calc(33.33% - 13.33px);
      background-color: #00000033;
      max-width: 189px;
      height: 189px;
      overflow: hidden;
      position: relative;
      border: 2px solid #e43c52;
      border-radius: 10px;
      &:not(:last-child) {
        margin-right: 20px;
      }
      .header {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 12px 0 12px;
        .icon {
          width: 30px;
          img {
            width: 100%;
          }
        }
        .close {
          width: 23px;
          height: 23px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .selected_text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
      }
      .selected_images {
        width: 100%;
        height: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .selected_music {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 10px;
    }
    .music_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #a4a4c04d;
      padding: 13px 16px;
      border-radius: 10px;
      margin-bottom: 15px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon {
          width: 30px;
          height: 30px;
          margin-right: 13px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .name {
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0px;
          color: #24242d;
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .minute {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #a4a4c0;
          margin-right: 20px;
        }
        .close {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.preview {
  width: 60%;
  margin: 34px auto 0 auto;
  .video_wrapper {
    background: linear-gradient(
      135.09deg,
      #00aab8 1.31%,
      #df196d 48.63%,
      #ee7923 98.54%
    );
    border-radius: 15px;
    position: relative;
    height: 435px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 14px;
    .logo {
      height: 170px;
      width: 170px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .play_button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .progressbar {
      padding: 17px 27px 17px 17px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .ply {
        height: 19px;
        width: 16px;
        margin-right: 20px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .slider {
        width: calc(100% - 36px);
        background-color: #ffffff4a;
        height: 11px;
        border-radius: 6px;
        margin-top: 5px;
      }
    }
  }
  .share_link {
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      135.09deg,
      #00aab814 1.31%,
      #df196d14 48.63%,
      #ee792314 98.54%
    );
    padding: 14px 25px 14px 29px;
    .content {
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #24242d;
      }
      .link {
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;
        font-size: 20px;
        text-decoration: none;
        background: linear-gradient(
          135.09deg,
          #00aab8 1.31%,
          #df196d 48.63%,
          #ee7923 98.54%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .make_preview {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 19px;
    button {
      border: 0;
      background: #24242d;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      padding: 13px 41px;
      color: #fafafb;
      border-radius: 50px;
    }
  }
}

.finalize {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 0 80px 0;
  .img {
    height: 82px;
    width: 82px;
    margin-bottom: 28px;
    img {
      width: 100%;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0px;
    color: #24242d;
    margin-bottom: 5px;
  }
  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #24242d;
  }
  button {
    padding: 13px 45px;
    min-width: 250px;
    text-align: center;
    background: linear-gradient(
      135.09deg,
      #00aab8 1.31%,
      #df196d 48.63%,
      #ee7923 98.54%
    );
    border-radius: 50px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #fff;
    text-decoration: none;
    margin-top: 34px;
  }
}
.success_page_wrapper {
  position: relative;
  overflow-x: hidden;
  .lottie1,
  .lottie2,
  .lottie3,
  .lottie4 {
    position: absolute;
    z-index: 1000;
    user-select: none;
  }
  .lottie1 {
    top: -50px;
    left: -50px;
  }
  .lottie2 {
    top: -50px;
    right: -50px;
    transform: rotate(45deg);
  }
  .lottie3 {
    left: 0;
    bottom: 0;
  }
  .lottie4 {
    right: 0;
    bottom: 0;
  }
  .InviteContent.css-1h05724 {
    z-index: 1000;
  }
}
.preview_video {
  padding: 7rem 1rem;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden scroll;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  // background: linear-gradient(135.09deg, #00aab8 1.31%, #df196d 48.63%, #ee7923 98.54%);
  // background-image: url("./assets/previewvideo.jpg");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // z-index: 999;
  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    background-size: 100%;
  }
  .lottie1,
  .lottie2,
  .lottie3,
  .lottie4 {
    position: absolute;
    z-index: 1000;
    * {

      cursor: default !important;
    }
  }
  .lottie1 {
    top: -50px;
    left: -50px;
  }
  .lottie2 {
    top: -50px;
    right: -50px;
    transform: rotate(45deg);
  }
  .lottie3 {
    left: 0;
    bottom: 0%;
  }
  .lottie4 {
    right: 0;
    bottom: 0%;
  }
  .email_invite_submission_btn {
    .btn-primary {
      padding: 13px 45px;
      background: linear-gradient(
        135.09deg,
        #00aab8 1.31%,
        #df196d 48.63%,
        #ee7923 98.54%
      );
      border-radius: 50px;
      border: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      color: #fff;
      text-decoration: none;
    }
    .btn-secondary {
      padding: 15px 30px;
      border-radius: 50px;
      border: 0;
      outline: none;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      background-color: #24242d;
    }
  }
  .preview_box {
    max-width: 844px;
    width: 100%;
    max-height: max-content;
    /* overflow-x: auto; */
    border: 1px solid rgba(164, 164, 192, 0.3019607843);
    background: #ffffff;
    border-radius: 25px;
    position: relative;
    z-index: 1001;
    .heading {
      padding: 45px 0 80px;
      text-align: center;
      align-items: center;
      // background-image: url("./assets/heading.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      border-radius: 25px 25px 0 0;
      // overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: #00000050;
        border-radius: 25px 25px 0 0;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: #24242d;
        z-index: 2;
        position: relative;
        text-shadow: 1px 1px 0px #ddd, -1px -1px 0px #ddd, 1px -1px 0px #ddd, -1px 1px 0px #ddd;
      }
      .name {
        font-size: 34px;
        font-weight: 800;
        line-height: 51px;
        letter-spacing: 0px;
        color: #24242d;
        z-index: 2;
        position: relative;
        text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, 1px -1px 0px #fff, -1px 1px 0px #fff;
      }
      .image {
        width: 150px;
        aspect-ratio: 1;
        border-radius: 15px;
        overflow: hidden;
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .video {
        width: 196px;
        height: 140px;
        border-radius: 15px;
        overflow: hidden;
        position: absolute;
        bottom: -45px;
        right: 46px;
        .thumb {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .upload {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 35px;
          img {
            width: 100%;
          }
        }
      }
    }
    .box_content {
      padding: 90px 36px 40px;
      .paragraph {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        margin-bottom: 25px;
        color: #24242d;
      }
    }
    .share_link {
      display: flex;
      border-radius: 15px;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(
        135.09deg,
        #00aab814 1.31%,
        #df196d14 48.63%,
        #ee792314 98.54%
      );
      padding: 14px 25px 14px 29px;
      .content {
        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: #24242d;
        }
        .link {
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0px;
          text-align: left;
          font-size: 20px;
          text-decoration: none;
          background: linear-gradient(
            135.09deg,
            #00aab8 1.31%,
            #df196d 48.63%,
            #ee7923 98.54%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .email_invite_deadline {
      border: 1px solid #a4a4c04d;
      margin-top: 25px;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      &.deadline {
        margin-top: 20px;
      }
      &.add_manually {
        border: 0;
        .input_lable {
          padding: 0;
        }
      }
      .deadline_date {
        font-size: 20px;
      }
      .input_lable {
        padding: 22px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        &.left {
          justify-content: left;
        }
        img {
          width: 36px;
          height: 36px;
          margin-right: 20px;
        }
        .send {
          button {
            padding: 10px 35px;
            text-align: center;
            background: linear-gradient(
              135.09deg,
              #00aab8 1.31%,
              #df196d 48.63%,
              #ee7923 98.54%
            );
            border-radius: 50px;
            border: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .custom_date {
        padding: 26px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        user-select: none;
        background: hsl(240deg 15.79% 96.27%);
        &.select_csv {
          display: flex;
          justify-content: center;
          align-items: center;
          input {
            display: none;
          }
          span {
            color: #a4a4c0;
          }
        }
        span {
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0px;
          color: #24242d;
        }
      }
      .input_fields {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        input {
          width: 48%;
          border: 1px solid #a4a4c04d;
          border-radius: 8px;
          padding: 16px 18px;
          outline: none;
          margin-right: 24px;
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            color: #a4a4c0;
          }
        }
        .add,
        .remove {
          background-color: transparent;
          border: 0;
        }
      }
    }
  }
  .exit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 13px 55px;
      border-radius: 50px;
      outline: none;
      background-color: #24242d;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      border: 0;
      color: #fafafb;
    }
  }
}

// Modal Design
.MuiDialog-paper {
  max-width: unset !important;
  border-radius: unset !important;
  background-color: unset !important;
  border-radius: 15px !important;
  overflow: hidden !important;
}
.modal-content {
  border-radius: 12px;
  overflow: hidden;
}
.add_modal {
  border: 1px solid #a4a4c04d;
  min-width: 690px;
  &.lg {
    min-width: 1176px;
    max-width: 1176px;
  }
  &.final {
    min-width: 450px;
    max-width: 450px;
    padding: 55px 43px;
  }
  .modal_header {
    padding: 35px 35px 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .icon {
      height: 36px;
      width: 36px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      margin-top: 20px;
    }
    .close {
      width: 25px;
      padding: 0;
      border: 0;
      background-color: transparent;
      position: absolute;
      right: 22px;
      top: 22px;
      img {
        width: 25px;
      }
    }
  }
  .upload_option {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    .option_box {
      input {
        display: none;
      }
      cursor: pointer;
      .icon {
        padding: 33px;
        background-color: #fff;
        border-radius: 10px;
        img {
          width: 30px;
        }
      }
      .name {
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0px;
        color: #24242d;
        text-align: center;
        margin-top: 10px;
      }
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  .type_your_message {
    width: 100%;
    height: 350px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    textarea {
      width: 100%;
      height: 100%;
      border: 0;
      margin: 0;
      padding: 0;
      resize: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      text-align: center;
      outline: none;
      vertical-align: middle;
      &::placeholder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .gif_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 8px 2px;
    max-height: 458px;
    overflow: auto;
    .gif_box {
      padding: 0 6px;
      width: 20%;
      margin-bottom: 10px;
    }
  }

  .MuiTabPanel-root {
    padding: 0;
  }
  .music_option {
    .MuiBox-root {
      border-bottom: 0px !important;
    }
    .MuiButtonBase-root {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0px;
      text-transform: capitalize;
      color: #a4a4c0;
      &.Mui-selected {
        color: #24242d;
      }
    }
    .MuiTabs-indicator {
      background: linear-gradient(
        135.09deg,
        #00aab8 1.31%,
        #df196d 48.63%,
        #ee7923 98.54%
      ) !important;
      height: 4px;
    }
    .music_container {
      max-height: 400px;
      overflow: auto;
      .music_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #fff;
        padding: 13px 19px;
        border-radius: 0px;
        margin-bottom: 0px;
        &:nth-child(2n) {
          background-color: #f4f4f7;
        }
        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            width: 30px;
            height: 30px;
            margin-right: 13px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .name {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0px;
            color: #24242d;
          }
        }
        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .minute {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            color: #a4a4c0;
            margin-right: 20px;
          }
          .close {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .finalize_popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 24px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0px;
      margin-bottom: 5px;
      color: #24242d;
    }
    .sub_title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: #24242d;
      margin-bottom: 30px;
    }
    .text {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #a4a4c0;
      text-align: center;
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 43px;
      button {
        border: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        outline: none;
        min-width: 250px;
        text-align: center;
        border-radius: 50px;
        &.submit {
          padding: 13px 45px;
          background: linear-gradient(
            135.09deg,
            #00aab8 1.31%,
            #df196d 48.63%,
            #ee7923 98.54%
          );
          color: #fff;
          text-decoration: none;
        }
        &.cancel {
          background-color: transparent;
          margin-top: 27px;
        }
      }
    }
  }

  .modal_footer {
    padding: 41px 40px 33px;
    background-color: #ffffff;
    .action {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .submit {
        padding: 13px 45px;
        min-width: 250px;
        text-align: center;
        background: linear-gradient(
          135.09deg,
          #00aab8 1.31%,
          #df196d 48.63%,
          #ee7923 98.54%
        );
        border-radius: 50px;
        border: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1920px) {
  .startForm .form_container .input_box .custom_select .dropdown .drop_option {
    padding: 18px 10px 18px 10px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .invitation {
    .tabs_wrapper {
      border-radius: 6px;
      .MuiBox-root {
        .MuiTabs-flexContainer {
          background-color: #fff;
          .MuiButtonBase-root {
            width: auto;
            border-bottom: 1px solid #f3f3f3;
            padding: 10px;
            &.Mui-selected {
              background-color: #f3f3f3;
            }
          }
        }
        .MuiTabPanel-root {
          padding: 20px;
        }
      }
      .participate_image {
        margin-bottom: 20px;
      }
      .tab_wrapper {
        padding: 10px;
        img {
          width: 45px;
        }
        .tab_text {
          p {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
  }

  .send_message {
    .message_content {
      margin-bottom: 30px;
    }
    .message_image {
      padding: 60px;
    }
  }

  .media_type_wrapper {
    margin-bottom: 30px;
  }

  .add_modal {
    min-width: 100%;
    &.final {
      padding: 15px;
      min-width: 600px;
      max-width: 600px;
    }
    &.lg {
      min-width: 700px;
      max-width: 700px;
    }
    .gif_wrapper {
      height: 400px;
      .gif_box {
        width: 25%;
        img {
          width: 100%;
        }
      }
    }
  }

  .preview {
    width: 100%;
    margin: 10px auto 0 auto;
  }
}

@media (max-width: 991px) {
  .preview_video .preview_box .box_content {
    padding: 40px 36px 40px;
}
  .preview_video .preview_box .heading {
    padding: 45px 0;
}
  .preview_video .preview_box .heading .image {
    margin: 0 auto 20px;
    position: relative;
    left: unset;
    bottom: unset;
    border-radius: 6px;
    transform: unset;
}
  .preview_video .preview_box .heading .video {
    height: 100px;
    width: 70%;
    margin: 20px auto 0;
    position: relative;
    right: unset;
    bottom: unset;
    border-radius: 6px;
}
}
@media (max-width: 767px) {
  .preview_video .preview_box .heading .image {
    width: 120px;
  }
  .invitation {
    padding: 15px 0;
    &::after {
      height: 250px;
    }
    .birth_title {
      .title {
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 0;
      }
      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tabs_wrapper {
      border-radius: 6px;
      .MuiBox-root {
        .MuiTabs-flexContainer {
          background-color: #fff;
          .MuiButtonBase-root {
            width: auto;
            border-bottom: 1px solid #f3f3f3;
            padding: 10px;
            &.Mui-selected {
              background-color: #f3f3f3;
            }
          }
        }
        .MuiTabPanel-root {
          padding: 10px;
        }
      }
      .tab_wrapper {
        img {
          width: 25px;
        }
        .tab_text {
          p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
          }
          span {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
      .participate_image {
        border-radius: 6px;
        height: auto;
        margin-bottom: 15px;
        .text {
          padding: 5px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .participate_content {
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 15px;
        .content_title {
          font-size: 16px;
          line-height: 20px;
        }
        p {
          margin: 10px 0 0 0;
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
        }
      }

      .add_video {
        // padding: 15px 10px;
        border-radius: 6px;
        margin-bottom: 15px;
        .desc {
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
        }
        .video_title {
          margin-bottom: 0;
          font-size: 16px;
        }
        .video_image {
          margin-bottom: 0;
        }
      }

      .input_box {
        margin-top: 0px;
        border-radius: 6px;
        margin-bottom: 15px;
        &.deadline {
          .input_lable {
            flex-direction: column;
            align-items: flex-start;
            .deadline_date {
              font-size: 12px;
              width: 100%;
              border-top: 1px solid #dfdfdf;
              margin-top: 5px;
              line-height: 18px;
              padding-top: 5px;
            }
          }
        }
        .input_lable {
          padding: 6px 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          .send {
            button {
              padding: 3px 20px;
              font-size: 12px;
            }
          }
        }
        .input_fields {
          margin-top: 10px;
          input {
            margin-top: 0px;
            width: 100%;
            padding: 8px 10px;
            border-radius: 6px;
            font-size: 12px;
            font-weight: 400;
            margin-right: 5px;
            &::placeholder {
              font-size: 10px;
            }
          }
          .add {
            img {
              width: 15px;
            }
          }
        }
        .custom_date {
          padding: 6px 10px;
          span {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
          img {
            width: 20px;
          }
        }
      }
    }
    .message_image {
      border-radius: 6px;
      padding: 20px;
      .mail_box {
        width: 150px;
        img {
          width: 100%;
        }
        .birth_name {
          padding: 25px;
          font-size: 10px;
          line-height: 12px;
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            width: 100px;
          }
        }
        .birth_icon {
          img {
            width: 46px;
          }
        }
      }
      .preview_button {
        button {
          padding: 6px 20px;
          font-size: 12px;
        }
      }
    }
    .action {
      padding-top: 15px;
      justify-content: center;
      .submit,
      .back {
        padding: 6px 35px;
        min-width: max-content;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .message_content {
    margin-left: 0;
    .share_link {
      border-radius: 6px;
      padding: 10px 12px;
      .content {
        .title {
          font-size: 12px;
          line-height: 16px;
        }
        .link {
          font-size: 10px;
          line-height: 14px;
        }
      }
      .icon {
        width: 35px;
        img {
          width: 100%;
        }
      }
    }
    .share_option {
      margin-top: 15px;
      .share_box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 25%;
        min-height: 60px;
        &:not(:last-child) {
          margin-right: 0px;
        }
        .share_icon {
          width: 30px;
          height: 30px;
          border-radius: 6px;
          img {
            width: 20px;
          }
        }
        .share_title {
          margin-top: 5px;
          font-size: 8px;
          line-height: 12px;
        }
      }
    }
  }

  .media_type_wrapper {
    .media_row {
      border-radius: 6px;
      padding: 5px 10px;
      margin-bottom: 10px !important;

      .icon {
        img {
          width: 20px;
        }
      }
      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .selected_wrapper {
    border-radius: 6px;
    padding: 10px;
    .selected_graphics {
      flex-direction: column;
      padding: 0;
      .cards {
        margin-right: 0;
        width: 100%;
        min-width: 100%;
        height: 115px;
        margin-bottom: 15px;
        &:not(:last-child) {
          margin-right: 0;
        }
        .header {
          padding: 6px 10px 0;
          .icon {
            width: 20px;
          }
          .close {
            width: 15px;
            height: 15px;
            img {
              margin-top: -10px;
            }
          }
        }
      }
    }
    .selected_music {
      margin-top: 0px;
      .music_wrapper {
        padding: 4px 10px;
        border-radius: 6px;
        .left {
          .icon {
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
          }
          .name {
            font-size: 10px;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .right {
          .minute {
            font-size: 12px;
            font-weight: 500;
            margin-right: 6px;
          }
          .close {
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }
  .preview {
    width: 100%;
    margin: 0 auto;
    .video_wrapper {
      border-radius: 6px;
      height: 145px;
      .logo {
        width: 40px;
        height: 50px;
      }
      .play_button {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
        }
      }
      .progressbar {
        padding: 10px;
        .ply {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }
        .slider {
          margin-top: 0;
          width: calc(100% - 20px);
          height: 6px;
        }
      }
    }
    .share_link {
      border-radius: 6px;
      padding: 10px 12px;
      .content {
        .title {
          font-size: 12px;
          line-height: 16px;
        }
        .link {
          font-size: 10px;
          line-height: 14px;
        }
      }
      .icon {
        width: 35px;
        img {
          width: 100%;
        }
      }
    }
  }
  .preview {
    .make_preview {
      margin-top: 0;
      margin-bottom: 5px;
      button {
        padding: 5px 20px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .finalize {
    padding: 15px 0px;
    button {
      padding: 6px 20px;
      min-width: max-content;
      font-weight: 400;
      font-size: 14px;
      margin-top: 10px;
    }
    .title {
      font-size: 18px;
      line-height: 24px;
    }
    .text {
      font-size: 12px;
    }
    .img {
      height: 40px;
      width: 40px;
      margin-bottom: 5px;
    }
  }
  .MuiDialog-paper {
    border-radius: 6px !important;
  }
  .MuiPaper-root {
    margin: 0 !important;
  }
  .add_modal {
    min-width: 100%;
    &.final {
      padding: 15px;
      min-width: 100%;
      max-width: 350px;
    }
    &.lg {
      min-width: 100%;
      max-width: 400px;
    }
    .modal_header {
      padding: 10px;
      .title {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
      .icon {
        width: 26px;
        height: 26px;
      }
      .close {
        right: 12px;
        top: 12px;
      }
    }
    .type_your_message {
      height: 200px;
      padding: 5px;
      textarea {
        height: 100%;
      }
    }
    .gif_wrapper {
      height: 230px;
      .gif_box {
        width: 33.33%;
        img {
          width: 100%;
        }
      }
    }
    .music_option {
      .MuiButtonBase-root {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        padding: 10px;
      }
      .MuiTabs-indicator {
        height: 2px;
      }
      .music_container {
        max-height: 250px;
        .music_wrapper {
          padding: 4px 10px;
          border-radius: 0px;
          .left {
            .icon {
              width: 18px;
              height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 6px;
            }
            .name {
              font-size: 10px;
              width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .right {
            .minute {
              font-size: 12px;
              font-weight: 500;
              margin-right: 6px;
            }
            .close {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }
    .modal_footer {
      padding: 10px;
      .action {
        button.submit {
          padding: 6px 30px;
          font-size: 14px;
          width: max-content;
          min-width: max-content;
        }
      }
    }
    .upload_option {
      padding: 20px;
      .option_box {
        &:not(:last-child) {
          margin-right: 12px;
        }
        .icon {
          padding: 15px;
        }
      }
    }
    .finalize_popup {
      .title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        text-align: center;
      }
      .sub_title {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-bottom: 10px;
        text-align: center;
      }
      .text {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-align: left;
        text-align: center;
      }
      .action {
        margin-top: 15px;
        button {
          font-size: 14px;
          font-weight: 500;
          &.submit {
            padding: 4px 20px;
            min-width: max-content;
          }
          &.cancel {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .preview_video {
    .lottie1,
    .lottie2 {
      display: none;
    }
    > img {
      top: -50px;
    }
    .preview_box {
      height: 100vh;
      // overflow-y: auto;
      // border-radius: 0;
      .heading {
        padding: 10px 0 18px;
        .title {
          font-size: 16px;
          line-height: 22px;
        }
        .name {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          margin-bottom: 10px;
        }
        .video {
          height: 100px;
          width: 70%;
          margin: 0 auto;
          position: relative;
          right: unset;
          bottom: unset;
          border-radius: 6px;
        }
      }
      .box_content {
        padding: 10px;
        .paragraph {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
      .share_link {
        border-radius: 6px;
        padding: 10px 12px;
        .content {
          .title {
            font-size: 12px;
            line-height: 16px;
          }
          .link {
            font-size: 10px;
            line-height: 14px;
          }
        }
        .icon {
          width: 35px;
          img {
            width: 100%;
          }
        }
      }
      .email_invite_deadline {
        .input_lable {
          font-size: 14px;
          padding: 8px 12px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;        
          }
        }
        .deadline_date {
          font-size: 14px;
        }
      }
    }
    .exit {
      button {
        padding: 6px 40px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .preview_video .email_invite_submission_btn .btn-primary {
    padding: 8px 28px;
    font-size: 14px;
}
}
@media (max-width: 420px) {
  .preview_video .preview_box .email_invite_deadline .input_lable {
    flex-direction: column;
  }
}